.o-scroll {
    position: fixed;
    top: 40%;
    transform: translateY(50%) rotate(-90deg);
    opacity: 0;

    @media (max-width: 800px) {
        display: none;
    }
}

.webgl-is-loaded .o-scroll {
    opacity: 1;
    transition: opacity .6s 1.4s ease-out;
}

.o-scroll--right {
    right: 0;
    transform: translateY(50%) rotate(90deg);
}

