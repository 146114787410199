.c-intro {
    font-family: 'Poppins', Helvetica, Arial, sans-serif;
    font-size: 6vw;
    line-height: 1.2;
    margin: 0;
}

.c-intro + .c-intro {
    margin-top: calc(5vh + 15vw);
}

.c-intro__block {
    width: 100%;
    display: flex;
    height: 14vw;
}

.c-intro__block--large {
    height: 21vw;
}

.c-intro__block--right {
    justify-content: flex-end;
}

.c-intro__image {
    flex-shrink: 0;
    margin: 1vw 0;
    margin-left: 2rem;
    height: calc(100% - 2vw);
    object-fit: contain;
    object-position: left;
    z-index: 1;
    aspect-ratio: 3/2;
    background-color: var(--primary-color);
}

.c-intro__image--left {
    margin-left: 0;
    object-position: right;
    margin-right: 2rem;
}

.c-intro__image--middle {
    margin-right: 2rem;
}

.c-intro .word {
    transition: color .2s ease-out, opacity .2s ease-out;
}

.c-intro .word:not(.is-visible) {
    opacity: 0.2;
    color: var(--primary-color);
}

.c-intro__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.c-intro__image--anim {
    position: relative;
    overflow: hidden;

    .c-intro__img {
        position: absolute;
        top: 0;
        left: 0;
        width: 200%;
        height: 200%;

        @media (prefers-reduced-motion: no-preference) {
            animation: animate .6s steps(1) infinite;
        }
    }

    @keyframes animate {
        0% {
            transform: translate(0, 0);
        }
        25% {
            transform: translate(-50%, 0);
        }
        50% {
            transform: translate(-50%, -50%);
        }
        75% {
            transform: translate(0, -50%);
        }
        100% {
            transform: translate(0, 0);
        }
    }
}

.c-intro--images {
    .c-intro + & {
        margin-top: 3vw;
    }

    .c-intro__image {
        height: 14vw;
        display: block;

        &:nth-child(5n+2) {
            margin-left: calc(70% - 21vw);
            margin-top: -3vw;
        }

        &:nth-child(5n+3) {
            margin-left: calc(50% - 21vw);
            margin-top: 3vw;
        }

        &:nth-child(5n+4) {
            margin-left: calc(90% - 21vw);
        }
    }
}