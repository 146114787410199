.o-reveal {
    position: relative;
    padding: 1rem 0;
    cursor: default;
}

.o-reveal__main {
    display: block;
    text-align: center;
    transition: transform .2s .25s ease-out, opacity .2s .25s ease-out, filter .2s .25s ease-out;
}

.o-reveal__items {
    white-space: nowrap;
    display: block;
    margin-top: 3rem;
    @media (min-width: 801px) {
        margin-top: 1rem;
    }
    [data-whatintent="mouse"] & {
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        top: 0;
        padding: 1rem 0;
        margin-top: 0;
        pointer-events: none;
    }
}

.o-reveal__item {
    display: inline-flex;
    flex-direction: column-reverse;
    align-items: center;
    @for $i from 1 through 3 {
        &:nth-child(#{$i}) {
            transition: transform .2s ($i - 1) * .05s ease-out, opacity .2s ($i - 1) * .05s ease-out, filter .2s ($i - 1) * .05s ease-out;
        }
    }
    [data-whatintent="mouse"] .o-reveal:not(:hover) & {
        transform: translateY(1.2rem);
        opacity: 0;
        filter: blur(1px);
    }
}

[data-whatintent="mouse"] .o-reveal:hover .o-reveal__items {
    pointer-events: auto;
}

[data-whatintent="mouse"] .o-reveal:hover .o-reveal__main {
    transform: translateY(-1.2rem);
    opacity: 0;
    filter: blur(1px);
    transition: transform .2s ease-out, opacity .2s ease-out, filter .2s ease-out;
}

.o-reveal__item:first-child,
.o-reveal__item:last-child {
    position: absolute;
}

.o-reveal__item:first-child {
    right: 100%;
}

.o-reveal__item:last-child {
    left: 100%;
}

.o-reveal__item:nth-child(2) {
    margin: 0 3rem;
}

.o-reveal__item svg {
    height: 2rem;
    margin-bottom: .5rem;
}

.o-reveal.is-hidden {
    pointer-events: none;
}