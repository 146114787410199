.c-header {
    position: fixed;
    width: 100%;
    z-index: 100;
    transform: translateY(-100%);
    opacity: 0;
    animation: appear 0s 1s;

    @keyframes appear {
        from {
            transform: translateY(-100%);
            opacity: 0;
        } to {
            transform: none;
            opacity: 1;
        }
    }
}
.js .c-header {
    transform: translateY(-100%);
    opacity: 0;
    filter: blur(1px);
}
.webgl-is-loaded .c-header {
    transform: none;
    opacity: 1;
    filter: blur(0);
    transition: transform .6s 1.4s ease-out, opacity .6s 1.4s ease-out, filter .6s 1.4s ease-out;
}

.c-header__nav {
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
    pointer-events: none;
    @media (min-width: 801px) {
        display: flex;
        justify-content: space-between;
    }
}

.c-header__title,
.c-header__time {
    pointer-events: all;
}

.c-header__seed {
    position: fixed;
    top: calc(40 * var(--vh) - 40vw);
    left: 50%;
    transform: translateX(-50%);
    transition: transform .2s ease-out, opacity .2s ease-out, filter .2s ease-out;
    z-index: 1;
    @media (min-width: 801px) {
        top: 0;
    }
}

.c-header__seed.is-hidden {
    transform: translate(-50%, -1.2rem);
    opacity: 0;
    filter: blur(1px);
}

.c-header__time {
    @media (max-width: 800px) {
        margin-top: .4rem;
    }
}