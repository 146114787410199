@font-face {
    font-family: 'NaN Jaune';
    src: url("../assets/fonts/NaNJaune-MaxiBlack.woff2") format("woff2"),
         url("../assets/fonts/NaNJaune-MaxiBlack.woff") format("woff");
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url("../assets/fonts/Poppins-SemiBold.woff2") format("woff2"),
         url("../assets/fonts/Poppins-SemiBold.woff") format("woff");
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins Italic';
    src: url("../assets/fonts/Poppins-SemiBoldItalic.woff2") format("woff2"),
         url("../assets/fonts/Poppins-SemiBoldItalic.woff") format("woff");
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}