.u-svg {
    stroke: $text;
    transition: stroke .6s cubic-bezier(0.16, 1, 0.3, 1);
}

body.is-dark .u-svg {
    stroke: $text-white;
}

.u-text {
    color: $text;
    transition: color .6s cubic-bezier(0.16, 1, 0.3, 1);
}

body.is-dark .u-text {
    color: $text-white;
}

.u-above-fixed {
    position: relative;
    z-index: 2;
}

.u-bg {
    background-color: $body-bg;
    transition: background-color .8s cubic-bezier(0.16, 1, 0.3, 1);
}

body.is-dark .u-bg {
    background-color: $body-bg-dark;
}

.u-blurred {
    filter: drop-shadow(0px 0px 2px rgba($text, 0.4));
}
.u-blurred-heavy {
    filter: blur(0.06vw) drop-shadow(0px 0px 0.4vw rgba($text, 0.4));
}

body.is-dark .u-blurred-heavy {
    filter: blur(0.06vw) drop-shadow(0px 0px 0.4vw rgba($text-white, 0.4));
}
body.is-dark .u-blurred {
    filter: drop-shadow(0px 0px 2px rgba($text-white, 0.4));
}

body.no-js .u-js-only {
    display: none;
}

.u-visually-hidden {
    opacity: 0;
    display: block;
    height: 0;
    width: 0;
}