.c-toggle {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
}

body.is-scrolled .c-toggle {
    z-index: 0;
}

.c-toggle__input {
    position: absolute;
    top: calc(50 * var(--vh));
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    border-radius: calc(100 * var(--vh));
    z-index: 1;
}

.js .c-toggle__input {
    cursor: pointer;
}

body.is-scrolled .c-toggle__input {
    pointer-events: none;
}

.c-toggle__canvas {
    opacity: 0;
    border-radius: calc(100 * var(--vh));
    overflow: hidden;
    transform: translate(-50%, -50%);
    transition: transform .6s cubic-bezier(0.16, 1, 0.3, 1), opacity .3s 1.4s ease-out;

    canvas {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: transform .6s cubic-bezier(0.16, 1, 0.3, 1);
    }
}

.c-toggle__loader,
.c-toggle__input,
.c-toggle__canvas {
    width: $canvas-width;
    height: $canvas-height;
}

.c-toggle__frame {
    width: calc($canvas-width * 1.2);
    height: calc($canvas-height * 1.2);
}

.c-toggle__loader,
.c-toggle__canvas,
.c-toggle__frame {
    position: absolute;
    top: calc(50 * var(--vh));
    left: 50%;
}

.c-toggle__loader {
    transform: translate(-50%, -50%);

    .js-path {
        stroke-dasharray: 1000;
        stroke-dashoffset: 1000;
        transition: stroke-dashoffset .6s ease-out, stroke-dasharray .6s ease-out;
    }
}

.c-toggle__frame {
    stroke-dasharray: 1rem 0 1rem calc($canvas-width * 1.2 - 2rem) 1rem 0 1rem calc($canvas-height * 1.2 - 2rem);
    stroke-dashoffset: 1rem;
    transform: translate(-50%, -50%);
    transition: stroke-dashoffset .6s ease-out, stroke-dasharray .6s cubic-bezier(0.16, 1, 0.3, 1), width .6s cubic-bezier(0.16, 1, 0.3, 1), height .6s cubic-bezier(0.16, 1, 0.3, 1);
}

.c-toggle__input[data-state="expanded"] {
    transform: translate(-50%, -50%) scale(1.3);
    @media (min-width: 801px) {
        transform: translate(-50%, -50%) scale(1.7);
    }
}

.c-toggle__input[data-state="expanded"] + .c-toggle__canvas {
    body:not(.is-scrolled) & {
        transform: translate(-50%, -50%) scale(1.3);
    }

    canvas {
        transform: translate(-50%, -50%) scale(calc(1/1.3));
    }

    @media (min-width: 801px) {
        body:not(.is-scrolled) & {
            transform: translate(-50%, -50%) scale(1.7);
        }

        canvas {
            transform: translate(-50%, -50%) scale(calc(1/1.7));
        }
    }
}

.c-toggle__input[data-state="expanded"] + .c-toggle__canvas + .c-toggle__frame {
    height: calc($canvas-height * 1.3);
    width: calc($canvas-width * 1.3);
    stroke-dasharray: 1rem 0 1rem calc($canvas-width * 1.3 - 2rem) 1rem 0 1rem calc($canvas-height * 1.3 - 2rem);
    @media (min-width: 801px) {
        height: calc($canvas-height * 1.7);
        width: calc($canvas-width * 1.7);
        stroke-dasharray: 1rem 0 1rem calc($canvas-width * 1.7 - 2rem) 1rem 0 1rem calc($canvas-height * 1.7 - 2rem);
    }
}

.c-toggle__canvas.is-loading + .c-toggle__frame {
    transition: stroke-dashoffset .3s ease-out;
}

@media (min-aspect-ratio: 1/1) {
    $canvas-width: calc(42.5 * var(--vh));
    $canvas-height: calc(25 * var(--vh));

    .c-toggle__loader,
    .c-toggle__input,
    .c-toggle__canvas {
        width: $canvas-width;
        height: $canvas-height;
    }

    .c-toggle__frame {
        width: calc($canvas-width * 1.1);
        height: calc($canvas-height * 1.1);
    }

    .c-toggle__frame {
        stroke-dasharray: 1rem 0 1rem calc($canvas-width * 1.1 - 2rem) 1rem 0 1rem calc($canvas-height * 1.1 - 2rem);
    }

    .c-toggle__input[data-state="expanded"] + .c-toggle__canvas + .c-toggle__frame {
        height: calc($canvas-height * 1.3);
        width: calc($canvas-width * 1.3);
        stroke-dasharray: 1rem 0 1rem calc($canvas-width * 1.3 - 2rem) 1rem 0 1rem calc($canvas-height * 1.3 - 2rem);
        @media (min-width: 801px) {
            height: calc($canvas-height * 1.7);
            width: calc($canvas-width * 1.7);
            stroke-dasharray: 1rem 0 1rem calc($canvas-width * 1.7 - 2rem) 1rem 0 1rem calc($canvas-height * 1.7 - 2rem);
        }
    }
}

.webgl-is-loaded {
    .c-toggle__canvas {
        opacity: 1;
    }

    .c-toggle__loader {
        opacity: 0;
        transition: opacity .3s 1.4s ease-out;
    }
}