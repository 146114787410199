html {
    font-size: 10px;
    --primary-color: #9B7646;
    --vw: 1vw;
    --vh: 1vh;

    ::selection {
        background-color: var(--primary-color);
    }

    @media (min-width: 1920px) {
        font-size: calc(10px + .2vw);
    }
}

body {
    color: $text;
    background-color: $body-bg;
    font-family: $font-family;
    font-size: $font-size-base;
    line-height: $line-height-base;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    transition: background-color .8s cubic-bezier(0.16, 1, 0.3, 1);
}

body.is-dark {
    background-color: $body-bg-dark;
}

body::before {
    content: '';
    position: fixed;
    top: calc(50 * var(--vh));
    left: 50%;
    height: 100vh;
    width: 100vw;
    background-image: $cross, $cross, $cross, $cross;
    background-repeat: no-repeat;
    background-size: 1.2rem;
    background-position: top left, top right, bottom left, bottom right;
    pointer-events: none;
    filter: drop-shadow(0px 0px 2px rgba($text, 0.4));
    transform: translate(-50%, -50%);
    transition: width .6s cubic-bezier(0.16, 1, 0.3, 1), height .6s cubic-bezier(0.16, 1, 0.3, 1), top .6s cubic-bezier(0.16, 1, 0.3, 1);
    @media (min-width: 801px) {
        top: 40%;
        background-size: 2rem;
    }
}
body.is-dark::before {
    background-image: $cross-white, $cross-white, $cross-white, $cross-white;
}
body.webgl-is-loaded::before {
    width: calc($canvas-width * 1.4);
    height: calc($canvas-height * 1.4);

    @media (min-width: 801px) {
        height: 60vh;
        width: calc(100vw - 10rem);
    }
}

body.is-loading::before {
    width: calc($canvas-width * 1.2);
    height: calc($canvas-height * 1.2);
    @media (min-aspect-ratio: 1/1) {
        $canvas-width: calc(42.5 * var(--vh));
        $canvas-height: calc(25 * var(--vh));

        top: calc(50 * var(--vh));
        width: calc($canvas-width * 1.4);
        height: calc($canvas-height * 1.4);
    }
}

em {
    font-family: 'Poppins Italic';
}

canvas {
    object-fit: cover;
}