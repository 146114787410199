// Typography
$font-family: Helvetica, Arial, sans-serif;
$font-size-base: 1.8em;
$line-height-base: 1.5;

// Sizes
$size-xxs: 1.4rem;
$size-xs: 1.6rem;
$size-s: 1.8rem;
$size-m: 2.7rem;
$size-l: 4rem;
$size-xl: 6rem;
$size-xxl: 13.5rem;

$canvas-width: calc(42.5 * var(--vw));
$canvas-height: calc(25 * var(--vw));

$margin--s: $size-m;
$margin--m: 9rem;

// Colors
$black: #171717;
$white: #f4f4f1;

$text: $black;
$text-white: $white;
$body-bg: $white;
$body-bg-dark: $black;

// Z-indexes
$z-index-nav: 100;

// Images
$cross: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 16'%3E%3Cpath d='M10.25.5v15M0 8.25h20' stroke='%23171717'/%3E%3C/svg%3E");
$cross-white: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 16'%3E%3Cpath d='M10.25.5v15M0 8.25h20' stroke='%23f4f4f1'/%3E%3C/svg%3E");
