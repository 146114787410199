.o-btn {
    background: none;
    border: none;
    padding: 0;
}

[data-whatintent="mouse"] .o-btn:hover,
[data-whatintent="mouse"] .o-btn.u-text:hover,
[data-whatintent="mouse"] .o-btn:hover .u-text,
[data-whatintent="keyboard"] .o-btn:focus,
[data-whatintent="keyboard"] .o-btn.u-text:focus,
[data-whatintent="keyboard"] .o-btn:focus .u-text,
.o-btn:active,
.o-btn.u-text:active,
.o-btn:active .u-text {
    color: var(--primary-color);
}

[data-whatintent="mouse"] .o-btn.u-svg:hover,
[data-whatintent="mouse"] .o-btn:hover .u-svg,
[data-whatintent="keyboard"] .o-btn.u-svg:focus
[data-whatintent="keyboard"] .o-btn:focus .u-svg,
.o-btn.u-svg:active,
.o-btn:active .u-svg {
    stroke: var(--primary-color);
}