.g-content {
    position: fixed;
    top: calc(60 * var(--vh) + 20vw - 3rem);
    @media (min-width: 801px) {
        top: calc(100 * var(--vh) - 15vw);
    }
}

.no-js .g-content {
    position: relative;
    z-index: 3;
}

.g-content__wrapper {
    position: absolute;
    top: 0;
    backface-visibility: hidden;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
}
body:not(.is-scrolled) .g-content__wrapper {
    background-color: transparent;
}

.g-content,
.g-content__wrapper {
    left: 0;
    width: 100%;
}

.g-content__title {
    padding-top: 2vh;
    padding-bottom: 2rem;
    margin: 0;
    font-family: NaN Jaune, Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 14.5vw;
    text-align: center;
    line-height: 1;
    @media (min-width: 801px) {
        padding-top: 0;
    }
}

.g-content__title-container {
    transform: translateY(100%);
    opacity: 0;
    animation: appear 0s 1s;

    @keyframes appear {
        from {
            transform: translateY(100%);
            opacity: 0;
        } to {
            transform: none;
            opacity: 1;
        }
    }
}
.js .g-content__title-container {
    transform: translateY(100%);
    opacity: 0;
    filter: blur(1px);
}
.webgl-is-loaded .g-content__title-container {
    transform: none;
    opacity: 1;
    filter: blur(0);
    transition: transform .6s 1.4s ease-out, opacity .6s 1.4s ease-out, filter .6s 1.4s ease-out;
}

.g-content__scroll {
    font-size: 1rem;
    font-weight: bold;
    position: absolute;
    left: 50%;
    top: calc(28 * var(--vh) - 20vw + 3rem);
    transform: translateX(-50%);
    @media (min-width: 801px) {
        display: none;
    }
}

.g-content__intro {
    margin: calc(30vh - 10vw) 2rem 0;
    line-height: 1.2;
}