.c-lazyload {
    position: relative;

    canvas {
        position: absolute;
        top: 0;
        left: 0;
    }
}

.c-lazyload__placeholder,
.c-lazyload__image,
.c-lazyload canvas {
    width: 100%;
}

.c-lazyload__placeholder,
.c-lazyload canvas {
    opacity: 0;
    transition: opacity .2s ease-out;
}